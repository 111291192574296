import { auth, db } from "@/libs/firebase-config";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useState } from "react";

const UseSetting = () => {
	const [first, setFirst] = useState(false);
	const updateUser = async (data: any) => {
		const userId: any = auth.currentUser?.uid;
		const userRef = doc(db, "users", userId);
		if (first) {
			try {
				await setDoc(userRef, {
					settings: { ...data },
				});
				alert("success");
			} catch (error) {
				alert(error);
			}
		} else {
			try {
				await updateDoc(userRef, {
					settings: { ...data },
				});
				alert("success");
			} catch (error) {
				alert(error);
			}
		}
	};

	const deleteDocData = async (uid: string, sId: string) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId, "studio", sId);
		// const collection = collection(userRef, 'stu');

		try {
			await deleteDoc(userRef);
			console.log("deleted", sId);
		} catch (error) {
			console.log(error);
		}
	};

	const getUserSetting = async (uid: any) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId);
		const user = await getDoc(userRef);

		if (user.exists()) {
			return user.data();
		} else {
			setFirst(true);
			return null;
		}
	};

	return { updateUser, first, deleteDocData, getUserSetting };
};

export default UseSetting;

import FirebaseImage from '@/components/firebaseImage';
import { auth, FBstorage } from '@/libs/firebase-config';
import { ref, uploadBytes } from 'firebase/storage';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';

function FileDropzone({ path, type, callBack, value }: any) {
  const uploadImage = (v: null | File, p: string) => {
    if (v == null) return;
    const imageRef = ref(FBstorage, `/company-${auth.currentUser?.uid}/${p}/${v4()}`);
    const res = uploadBytes(imageRef, v).then((snapShot) => {
      return snapShot.metadata.fullPath;
    });
    return res;
  };
  const onDrop = async (acceptedFiles: File[]) => {
    // Do something with the files
    console.log(acceptedFiles);
    const v = await uploadImage(acceptedFiles[0], path);
    callBack(v);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className="border mt-2 relative  cursor-pointer h-48 border-gray-100 w-full overflow-hidden rounded-md"
      {...getRootProps()}>
      <input {...getInputProps()} />
      {!isDragActive ? (
        <div className="flex items-center h-full justify-center">
          <p className="text-center">
            Drag & Drop {type === 'image' || type === 'cover_image' ? 'image' : 'video'}
          </p>
        </div>
      ) : (
        <div className="flex items-center h-full justify-center">
          <p className="text-center">Drag 'n' drop some files here, or click to select files</p>
        </div>
      )}
      {((value && type === 'image') || (value && type === 'cover_image')) && (
        <div className="w-full h-full absolute top-0 left-0 ">
          <FirebaseImage path={value} className=" w-full h-full object-cover" />
        </div>
      )}
    </div>
  );
}

export default FileDropzone;

'use client';
import classNames from 'classnames';
import React, { useState } from 'react';
import { RegisterOptions } from 'react-hook-form';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: any;
  rules?: RegisterOptions;
  register?: any;
  errors?: any;
}

// eslint-disable-next-line complexity
const Input = ({ name, register, errors = {}, rules, ...rest }: InputProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const inputType = showPassword ? 'text' : rest.type;

  return (
    <div className="relative">
      <input
        {...(register && register(name, rules))}
        {...rest}
        className={classNames(
          rest.className,
          errors[name]
            ? 'shake !border !border-red-500 select: focus:outline-none'
            : 'border-gray-100 ',
          'block w-full  appearance-none rounded-md  border  px-3 py-3 placeholder-gray-400 shadow-sm sm:text-sm'
        )}
        name={name}
        type={inputType}
        role="input"
      />
      {rest.type === 'password' && (
        <span
          role="toggle"
          className="absolute cursor-pointer right-4 top-3 input-add-on "
          onClick={toggleShowPassword}>
          {showPassword ? (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7603 9.58277C18.8047 9.63389 18.8443 9.69005 18.8778 9.75072C19.0496 10.0609 19.0396 10.4398 18.8518 10.7405C17.8802 12.2966 16.8772 13.6115 15.84 14.6534L16.3743 15.1877C16.7555 15.5689 16.7555 16.187 16.3743 16.5682C15.9931 16.9494 15.3751 16.9494 14.9939 16.5682L14.3601 15.9343C12.9783 16.9409 11.5369 17.4677 10.0294 17.4467C6.85976 17.4026 3.94651 14.936 1.2396 10.8644C1.19531 10.8135 1.15595 10.7575 1.12246 10.6971C0.950415 10.3869 0.960255 10.0077 1.14816 9.70677C2.12914 8.1358 3.14197 6.81072 4.18979 5.76407L3.65557 5.22984C3.27437 4.84865 3.27437 4.2306 3.65557 3.84941C4.03676 3.46821 4.65481 3.46821 5.036 3.84941L5.6745 4.4879C7.04572 3.49769 8.4756 2.97979 9.97055 3.0006C13.1402 3.04474 16.0534 5.51125 18.7603 9.58277ZM7.07814 5.89154C8.0802 5.23001 9.03697 4.94003 9.94337 4.95265C11.9997 4.98128 14.3178 6.56624 16.8515 10.2453C16.0325 11.4959 15.2348 12.4961 14.4605 13.2739L7.07814 5.89154ZM5.56927 7.14355L12.9577 14.532C11.9426 15.2104 10.9738 15.5074 10.0566 15.4947C8.00024 15.466 5.68216 13.8811 3.14847 10.2021C3.97801 8.93538 4.78566 7.92555 5.56927 7.14355Z"
                  fill="#050B1E"
                />
                <path
                  d="M10.0049 8.72436C10.3015 8.72436 10.5913 8.81229 10.8379 8.97704C11.0845 9.14178 11.2766 9.37594 11.3901 9.64991C11.5036 9.92387 11.5333 10.2253 11.4754 10.5162C11.4176 10.807 11.2748 11.0742 11.0651 11.2838C10.8554 11.4935 10.5883 11.6363 10.2974 11.6942C10.0066 11.752 9.70512 11.7223 9.43116 11.6089C9.15719 11.4954 8.92303 11.3032 8.75829 11.0566C8.59354 10.8101 8.50561 10.5202 8.50561 10.2237C8.50606 9.82617 8.66416 9.44507 8.94524 9.16399C9.22632 8.88291 9.60742 8.72481 10.0049 8.72436ZM10.0049 7.1001C9.38714 7.1001 8.78323 7.28329 8.26956 7.62651C7.75589 7.96974 7.35553 8.45757 7.11912 9.02833C6.8827 9.59909 6.82084 10.2271 6.94137 10.833C7.06189 11.439 7.35938 11.9955 7.79622 12.4324C8.23306 12.8692 8.78963 13.1667 9.39554 13.2872C10.0015 13.4077 10.6295 13.3459 11.2003 13.1095C11.771 12.8731 12.2589 12.4727 12.6021 11.959C12.9453 11.4454 13.1285 10.8415 13.1285 10.2237C13.1285 9.39525 12.7994 8.60075 12.2136 8.01497C11.6278 7.42919 10.8333 7.1001 10.0049 7.1001Z"
                  fill="#050B1E"
                />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.7603 9.58277C16.0534 5.51125 13.1402 3.04474 9.97055 3.0006C6.74897 2.95574 3.82957 5.41265 1.14816 9.70677C0.960255 10.0077 0.950415 10.3869 1.12246 10.6971C1.15595 10.7575 1.19531 10.8135 1.2396 10.8644C3.94651 14.936 6.85976 17.4026 10.0294 17.4467C13.251 17.4916 16.1704 15.0347 18.8518 10.7405C19.0396 10.4398 19.0496 10.0609 18.8778 9.75071C18.8442 9.69005 18.8047 9.63388 18.7603 9.58277ZM3.14846 10.2021C5.62821 6.41558 7.91227 4.92436 9.94337 4.95265C11.9997 4.98128 14.3178 6.56624 16.8515 10.2453C14.3718 14.0317 12.0877 15.5229 10.0566 15.4947C8.00024 15.466 5.68216 13.8811 3.14846 10.2021Z"
                  fill="#050B1E"
                />
                <path
                  d="M10.0049 8.72448C10.3015 8.72448 10.5913 8.81241 10.8379 8.97716C11.0845 9.1419 11.2766 9.37607 11.3901 9.65003C11.5036 9.92399 11.5333 10.2255 11.4754 10.5163C11.4176 10.8071 11.2748 11.0743 11.0651 11.284C10.8554 11.4937 10.5883 11.6364 10.2974 11.6943C10.0066 11.7522 9.70512 11.7225 9.43116 11.609C9.15719 11.4955 8.92303 11.3033 8.75829 11.0568C8.59354 10.8102 8.50561 10.5203 8.50561 10.2238C8.50606 9.82629 8.66416 9.44519 8.94524 9.16411C9.22632 8.88303 9.60742 8.72493 10.0049 8.72448ZM10.0049 7.10022C9.38714 7.10022 8.78323 7.28341 8.26956 7.62664C7.75589 7.96986 7.35553 8.45769 7.11912 9.02845C6.8827 9.59921 6.82084 10.2273 6.94137 10.8332C7.06189 11.4391 7.35938 11.9957 7.79622 12.4325C8.23306 12.8693 8.78963 13.1668 9.39554 13.2873C10.0015 13.4079 10.6295 13.346 11.2003 13.1096C11.771 12.8732 12.2589 12.4728 12.6021 11.9592C12.9453 11.4455 13.1285 10.8416 13.1285 10.2238C13.1285 9.39537 12.7994 8.60088 12.2136 8.01509C11.6278 7.42931 10.8333 7.10022 10.0049 7.10022Z"
                  fill="#050B1E"
                />
              </svg>
            </div>
          )}
        </span>
      )}

      {errors[name] && (
        <p className="mx-1 mt-2 text-xs text-red-500 capitalize" role="alert">
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
};

export default Input;

import classNames from 'classnames';
import React, { useRef, useState } from 'react';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
};

const Accordion = ({ title, children }: AccordionProps) => {
  const [isOpened, setOpened] = useState<boolean>(false);

  const [height, setHeight] = useState('0');

  const contentElement = useRef<any>(null);

  const HandleOpening = () => {
    setOpened(!isOpened);
    setHeight(!isOpened ? `${contentElement.current.scrollHeight}` : '0');
  };

  return (
    <div onClick={HandleOpening} className="">
      <div className="flex gap-3 cursor-pointer">
        <div className={classNames(isOpened && 'rotate-90')}>&#9654;</div>
        <div className="flex items-center gap-2">
          <p className="text-label-md">{title}</p>
        </div>
      </div>
      <div
        ref={contentElement}
        style={{ height: `${height}px` }}
        className="overflow-hidden transition-all duration-500">
        <div className="py-2 px-2">{children}</div>
      </div>
    </div>
  );
};

export default Accordion;

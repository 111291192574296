import { Input } from "@/components";
import FirebaseImage from "@/components/firebaseImage";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { Pencil, Plus } from "lucide-react";
import { useEffect, useState } from "react";
import { v4 } from "uuid";

const UpdateDialogPage = ({
	uploadImage,
	contentData,
	text,
	callback,
}: any) => {
	const [data, setData] = useState<any>({
		name: "",
		icons: null,
		route: "",
	});
	const handleChange = (e: any) => {
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const addIcon = async () => {
		const uploadIcon =
			data.icons instanceof File
				? await uploadImage(data.icons, "icons")
				: data.icons;

		callback({
			id: v4(),
			name: data.name,
			icons: uploadIcon,
			route: data.route,
		});
	};
	useEffect(() => {
		setData(contentData);
	}, []);

	return (
		<Dialog>
			<DialogTrigger>
				<div className="hover:bg-gray-300/30 w-5 h-5  flex items-center justify-center rounded-sm">
					<Pencil size={14} />
				</div>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle> {text}</DialogTitle>
					<DialogDescription>
						<p className="text-label-md font-normal my-2 text-secondary-text">
							Name
						</p>
						<Input
							name="name"
							value={data.name}
							onChange={handleChange}
							placeholder="eg. home"
						/>
						<p className="text-label-md font-normal my-2 text-secondary-text">
							Route
						</p>
						<div className="relative">
							<p className=" absolute left-0 rounded-s-md z-10 top-0 py-3 bg-white px-4 border text-black ">
								{"/"}
							</p>
							<Input
								value={data.route}
								name="route"
								onChange={handleChange}
								placeholder="eg.shop"
								className="pl-14 outline-none "
							/>
						</div>
						<div className="mt-5">
							<p className="text-label-md font-normal my-2 text-secondary-text">
								Icons
							</p>
							<label htmlFor="icons" className="mt-5 relative cursor-pointer">
								<div className="rounded-md border flex justify-center items-center w-20 h-20">
									<Plus className="w-10 h-10" />
								</div>
								<input
									type="file"
									id="icons"
									accept="image/*"
									className="hidden"
									onChange={(e: any) =>
										setData({ ...data, icons: e.target.files[0] })
									}
								/>
								<div>
									{data.icons && (
										<div className=" absolute top-0 bg-gray-100 left-0 z-10 border flex justify-center items-center w-20 h-20">
											{data.icons instanceof File ? (
												<img
													width="63"
													height="50"
													className="w-full h-full rounded object-cover scale-10"
													src={URL.createObjectURL(data.icons as any)}
													alt="license"
												/>
											) : (
												<FirebaseImage
													className="object-contain"
													path={data.icons}
												/>
											)}
										</div>
									)}
								</div>
							</label>
							<p className="text-xs mt-2">(450 x 84 px required)</p>
						</div>

						<div className="flex gap-3 mt-4 justify-end">
							<DialogClose>
								<button
									type="button"
									className="inline-flex items-center gap-x-1.5 rounded-md text-black bg-white border px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-gray-600	focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Cancel
								</button>
							</DialogClose>
							<DialogClose>
								<button
									type="button"
									onClick={addIcon}
									className="inline-flex items-center gap-x-1.5 rounded-md bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600	focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									Update
								</button>
							</DialogClose>
						</div>
					</DialogDescription>
				</DialogHeader>
			</DialogContent>
		</Dialog>
	);
};

export default UpdateDialogPage;

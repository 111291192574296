import { useState } from 'react';
import { Input, Loading } from 'src/components';
import { useAuth } from 'src/hooks/useAuth';
import Layout from './components/layout';

export default function LoginPage() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { login, ErrorMessage } = useAuth();

  const handleSignIn = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ email: email, password: password });
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <form onSubmit={handleSignIn}>
        <h1 className="mb-1 text-3xl text-center text-gray-900">BEYOND NATIONS</h1>
        <p className="text-sm font-normal text-center text-gray-600 mb-7">
          Please Sign in a account
        </p>

        <div className="py-5">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-600">
            Email
          </label>
          <div className="mt-2">
            <Input
              name="phone"
              placeholder="test@gmail.com"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="pb-5">
          <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-600">
            Password
          </label>
          <div className="mt-2">
            <Input
              name="password"
              placeholder="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {ErrorMessage && <p className="pt-2 text-center text-red-800">{ErrorMessage}</p>}
        </div>
        <div className="flex flex-row justify-between mb-5">
          <div className="inline-flex flex-row gap-x-2">
            <input type="checkbox" />
            <p>Remember Me</p>
          </div>
          <p className="text-sm">Forget Password?</p>
        </div>
        <button
          disabled={loading}
          type="submit"
          className="flex w-full cursor-pointer  justify-center rounded-md bg-[#333333] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-primary/70">
          {loading && <Loading />}
          Sign In
        </button>
      </form>
    </Layout>
  );
}

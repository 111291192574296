import Circle from "@uiw/react-color-circle";
import Colorful from "@uiw/react-color-colorful";
import classNames from "classnames";
import { MoreHorizontal } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";

type ColorPickerProps = {
	currentColor: string;
	setCurrentColor: (v: string) => void;
};

export default function ColorPicker({
	currentColor,
	setCurrentColor,
}: ColorPickerProps) {
	const colorList = ["#f44e3b", "#fe9200", "#e0e0e0", "#fcdc00", "#dbdf00"];

	return (
		<div className="flex">
			<Circle
				colors={colorList}
				color={currentColor}
				onChange={(color) => {
					setCurrentColor(color.hex);
				}}
			/>
			<Popover>
				<PopoverTrigger asChild>
					<div
						style={{
							background: colorList.includes(currentColor)
								? "gray"
								: currentColor,
							border: colorList.includes(currentColor)
								? "unset"
								: "2px solid black",
						}}
						className={classNames(
							"flex justify-center items-center rounded-full w-[28px] h-[28px]"
						)}
					>
						<MoreHorizontal color="#fff" fill="#fff" />
					</div>
				</PopoverTrigger>
				<PopoverContent style={{ width: 200, position: "relative" }}>
					<div className="absolute z-10 w-[200px]  bg-white border border-gray-200 rounded-md shadow-md top-0 left-0">
						<Colorful
							color={currentColor}
							onChange={(color) => {
								setCurrentColor(color.hexa);
							}}
						/>
					</div>
				</PopoverContent>
			</Popover>
		</div>
	);
}

import { createContext } from 'react';

export const defaultProps: any = {};

const ThemeSettingContext = createContext({
  ...defaultProps,
  changeThemeColor: () => {}
});

export { ThemeSettingContext };

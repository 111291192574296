import { LayoutProvider } from "@/hooks/useLayout";
import { Navigate, useOutlet } from "react-router-dom";
import { MainLayout } from "../components";
import { useAuth } from "../hooks/useAuth";

export const ProtectedLayout = () => {
	const { user } = useAuth();

	const outlet = useOutlet();

	if (!user) {
		return <Navigate to="/" />;
	}

	return (
		<LayoutProvider>
			<MainLayout>{outlet}</MainLayout>
		</LayoutProvider>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from '@/hooks/useAuth';
import { db } from '@/libs/firebase-config';
import { collection, doc, setDoc } from 'firebase/firestore';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { v4 } from 'uuid';
import UseCustom from '../../hooks/useCustom';
import { ThemeSettingContext } from './themeContext';

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ThemeSettingProvider = ({ children }: any) => {
  const params: any = useParams();
  const [MenuData, setMenuData] = useState<any>();
  const { user }: any = useAuth();
  const [uiData, setuidData] = useState([]);
  const { getStudioStyle, getMenuDetail } = UseCustom();
  const { id, pageId } = useParams();

  const [loading, setLoading] = useState(false);
  const [settingList, setSettingList] = useState<any>();
  const [slug, setSlug] = useState('');
  const navigate = useNavigate();
  const [checkbox, setCheckBox] = useState(false);

  const [updateSettingData, setUpdateSettingData] = useState<any>();

  const fetchData = async () => {
    setLoading(true);
    if (pageId) {
      try {
        const menu: any = await getMenuDetail(user?.uid, id, pageId);

        const data: any = await getStudioStyle(user?.uid, id);
        const studio = { ...data, ...menu.formData };

        const oldStudioPage_content = studio.page_content.map((item: any) => ({
          ...item,
        }));
        setMenuData(menu);
        setSlug(menu?.slug);
        setCheckBox(menu?.status);
        if (studio) {
          setuidData({ ...studio });
          filterSetting({ ...studio, page_content: oldStudioPage_content });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const data: any = await getStudioStyle(user?.uid, id);
        const studio = { ...data };

        const oldStudioPage_content = studio.page_content.map((item: any) => ({
          ...item,
        }));

        if (studio) {
          setuidData({ ...studio });
          filterSetting({ ...studio, page_content: oldStudioPage_content });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const filterSetting = (studio: any) => {
    setSettingList(studio);

    setUpdateSettingData(studio.page_content[0]);
  };

  const addCompontent = (components: any) => {
    const newpageContent = [...settingList.page_content, components];
    setSettingList({ ...settingList, page_content: newpageContent });
  };

  //c

  const updateFixData = (id: any, updateValue: any) => {
    const newJson = settingList.page_content?.map((item: any) =>
      item._uuid === id ? { ...updateValue } : { ...item }
    );
    setSettingList({ ...settingList, page_content: newJson });
  };

  const changeSetting = (uuid: string) => {
    const newSetting = settingList?.page_content.find((item: any) => {
      return item._uuid === uuid;
    });

    setUpdateSettingData(newSetting);
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchData();
  }, []);

  function createSlug(inputString: string) {
    // Convert the string to lowercase
    let replaceSlug = inputString.toLowerCase();

    // Remove special characters and spaces
    replaceSlug = replaceSlug.replace(/[^\w\s-]/g, ''); // Remove non-word characters except spaces and hyphens
    replaceSlug = replaceSlug.replace(/\s+/g, '-'); // Replace spaces with hyphens

    return replaceSlug;
  }

  const handleSave = () => {
    if (!slug) {
      return alert('please add slug input');
    }
    const newslug = createSlug(slug);
    if (pageId) {
      const newslug = createSlug(slug);
      const data = {
        id: pageId,
        slug: newslug,
        formData: settingList,
        created_at: MenuData?.created_at,
        status: checkbox,
        updated_at: moment.utc(new Date()).format(),
      };
      addMenuDoc(data, params.id);
    } else {
      const data = {
        id: v4(),
        slug: newslug,
        formData: settingList,
        created_at: moment.utc(new Date()).format(),
        status: checkbox,
      };
      console.log(data, 'data');
      addMenuDoc(data, params.id);
    }
  };

  const addMenuDoc = async (documents: any, sid: any) => {
    const userId: any = user?.uid;
    const userRef = doc(db, 'users', userId, 'studio', sid);
    const MenuCollection = collection(userRef, 'menu');
    const documentId = documents.id;
    try {
      const documentRef = doc(MenuCollection, documentId);

      await setDoc(documentRef, documents);

      navigate(-1);
    } catch (error) {
      console.error(`Error adding document with ID: ${documentId}`, error);
    }
  };

  const removeItem = (uuid: string) => {
    const newSetting = settingList?.page_content.filter((item: any) => {
      return item._uuid !== uuid;
    });
    console.log(newSetting, uuid);

    setSettingList({ ...settingList, page_content: newSetting });
  };

  return (
    <ThemeSettingContext.Provider
      value={{
        loading,
        setSettingList,
        uiData,
        settingList,
        filterSetting,
        addCompontent,
        updateFixData,
        updateSettingData,
        setUpdateSettingData,
        changeSetting,
        handleSave,
        removeItem,
        checkbox,
        setCheckBox,
        slug,
        setSlug,
      }}
    >
      {children}
    </ThemeSettingContext.Provider>
  );
};

export { ThemeSettingProvider };

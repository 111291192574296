import { useContext } from 'react';
import { ThemeSettingContext } from '../../context/themeContext';
import CoverImageLayout from './coverImageLayout';
import RenderChildComponent from './renderChildComponent';

const RenderUi = () => {
  const { settingList } = useContext(ThemeSettingContext);
  console.log(settingList);
  return (
    <>
      <div className="max-w-[350px] mx-auto py-5 overflow-auto bg-black w-full h-full">
        {settingList?.page_content.map((item: any) => {
          if (item.isHidden) {
            switch (item.components) {
              case 'cover':
                return <CoverImageLayout item={item} />;
              default:
                return <RenderChildComponent item={item} />;
            }
          }
        })}
      </div>
    </>
  );
};

export default RenderUi;

import Navbar from "./components/navbar";
import RenderUi from "./components/renderui";
import Sidebar from "./components/settings";
import Setting from "./components/sidebar";
import { ThemeSettingProvider } from "./context/themeProvider";

const Editor = () => {
	return (
		<ThemeSettingProvider>
			<div>
				<Navbar />
				<Setting />
				<div className="relative  z-[-1] min-h-screen overflow-hidden  w-full grid grid-cols-12">
					<div className="col-span-3"></div>
					<div className="col-span-9 mt-20">
						<RenderUi />
					</div>
					{<div className="col-span-3"></div>}
				</div>
				<Sidebar />
			</div>
		</ThemeSettingProvider>
	);
};

export default Editor;

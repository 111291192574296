import { signInWithEmailAndPassword } from 'firebase/auth';
import { createContext, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from 'src/libs/firebase-config';
import { useLocalStorage } from './useLocalStorage';

const AuthContext = createContext({
  user: null,
  ErrorMessage: '',
  token: null,
  logout: () => {},
  login: (data: any) => {}
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useLocalStorage('user', null);

  const [token, setToken] = useLocalStorage('accessToken', null);

  const [ErrorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();

  const login = async (data: any) => {
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then(async (userCredential) => {
        const crenditaluser = userCredential.user;

        const accessToken = await crenditaluser.getIdToken();
        setUser(crenditaluser);
        setToken(accessToken);
        navigate('/dashboard');
      })
      .catch((error) => {
        const errorCode = error.code;
        const cleanErrorMessage = errorCode.replace('auth/', '');
        setErrorMessage(cleanErrorMessage);
      });
  };

  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      ErrorMessage,
      token
    }),
    [user, ErrorMessage]
  );
  console.log(value, user);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

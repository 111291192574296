import { db } from "@/libs/firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "./useAuth";

const LayoutContext = createContext({
	routeList: [],
	handleReload: () => {},
});

type LayoutProviderProps = {
	children: React.ReactNode;
};

export const LayoutProvider = ({ children }: LayoutProviderProps) => {
	const [triggerReload, setTriggerReload] = useState(false);
	const [routeList, setRouteList] = useState<any>([]);
	const { user: userAuth }: any = useAuth();

	const setSetting = async () => {
		try {
			const data: any = await getUserSetting(userAuth?.uid);
			if (data) {
				setRouteList([...data?.settings.content]);
			}
		} catch (error) {}
	};

	const getUserSetting = async (uid: any) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId);
		const user = await getDoc(userRef);

		if (user.exists()) {
			return user.data();
		}
	};

	useEffect(() => {
		setSetting();
	}, []);

	useEffect(() => {
		if (triggerReload) {
			setSetting();
		}
	}, [triggerReload]);

	const handleReload = () => {
		setTriggerReload(true);
		setTimeout(() => {
			setTriggerReload(false);
		}, 2000);
	};
	console.log(triggerReload, routeList);
	return (
		<LayoutContext.Provider value={{ routeList, handleReload }}>
			{children}
		</LayoutContext.Provider>
	);
};

export const useLayout = () => {
	return useContext(LayoutContext);
};

"use client";
import { DropDown, IconInput, PlusIcon } from "../../../components/index";
import { dummyData } from "../../../utils/dummydata";
import { Eye, File, More, Pencil } from "../../../utils/menuList";

const EcommercePage = () => {
	return (
		<div className="p-10">
			<div className="flex flex-row gap-4">
				<File />
				<h1>Static Pages</h1>
			</div>
		</div>
	);
};

export default EcommercePage;

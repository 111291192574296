import { auth, db } from "@/libs/firebase-config";
import {
	collection,
	deleteDoc,
	doc,
	getDoc,
	getDocs,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { useState } from "react";

const UseCustom = () => {
	const [first, setFirst] = useState(false);
	const updateUser = async (data: any) => {
		const userId: any = auth.currentUser?.uid;
		const userRef = doc(db, "users", userId);
		if (first) {
			try {
				await setDoc(userRef, {
					settings: { ...data },
				});
				alert("success");
			} catch (error) {
				alert(error);
			}
		} else {
			try {
				await updateDoc(userRef, {
					settings: { ...data },
				});
				alert("success");
			} catch (error) {
				alert(error);
			}
		}
	};
	const getMenuDetail = async (uid: string, sId: any, mId: any) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId, "studio", sId, "menu", mId);

		try {
			const user = await getDoc(userRef);

			if (user.exists()) {
				const data: any = user.data();
				return data;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const getCustomList = async (uid: any, sId: any) => {
		const userId: any = uid;

		const CustomRef = doc(db, "users", userId, "studio", sId);
		const user = await getDoc(CustomRef);

		if (user.exists()) {
			const studioCollectionRef = collection(CustomRef, "menu");
			const menu = await getDocs(studioCollectionRef);
			const menuLists: any[] = [];
			menu.forEach((v: any) => {
				const studioData = v.data();
				menuLists.push(studioData);
			});
			return { ...user.data(), menuLists };
		} else {
			setFirst(true);
			return null;
		}
	};

	const getStudioStyle = async (uid: string, sId: any) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId, "studio", sId);

		try {
			const user = await getDoc(userRef);

			if (user.exists()) {
				const data: any = user.data();
				return data;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const deleteItem = async (uid: string, sId: any, mId: any) => {
		const userId: any = uid;
		const userRef = doc(db, "users", userId, "studio", sId, "menu", mId);
		console.log(userRef.path);
		await deleteDoc(userRef);
	};

	return {
		updateUser,
		getStudioStyle,
		getMenuDetail,
		getCustomList,
		deleteItem,
	};
};

export default UseCustom;

import { useContext } from 'react';
import { ThemeSettingContext } from '../context/themeContext';

const UseSortable = () => {
  const { settingList, updateSettingData, setUpdateSettingData, setSettingList } =
    useContext(ThemeSettingContext);

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const data = reorder(settingList.page_content, oldIndex, newIndex);

    const newData = { ...settingList, page_content: data };
    setSettingList(newData);
  };

  const handleChangeHidden = (id: any, updateItem: any, updateValue: any) => {
    const newJson = settingList.page_content.map((item: any) =>
      item._uuid === id ? { ...item, [updateItem]: updateValue } : { ...item }
    );
    const newSetting = newJson.find((item: any) => {
      return item._uuid === updateSettingData._uuid;
    });
    setUpdateSettingData(newSetting);
    setSettingList({ ...settingList, page_content: newJson });
  };

  return { onSortEnd, reorder, handleChangeHidden };
};

export default UseSortable;

import FirebaseImage from "@/components/firebaseImage";

const RenderChildComponent = ({ item }: any) => {
	console.log(item);
	return (
		<div className="relative mx-5 ">
			{item?.data.map((comp: any) => {
				switch (comp.name) {
					case "head":
						return (
							<h3 className=" text-white py-2 z-1 text-3xl font-bold">
								{comp.value}
							</h3>
						);
					case "paragraph":
						return (
							<div
								className=" text-white py-2"
								dangerouslySetInnerHTML={{ __html: comp.value }}
							></div>
						);
					case "cover_paragraph":
						return (
							<div
								className=" text-white py-2"
								dangerouslySetInnerHTML={{ __html: comp.value }}
							></div>
						);
					case "image":
						return (
							<div className="h-[500px]">
								<FirebaseImage
									className="h-full z-0 object-cover  w-full"
									path={item.data[0].value}
									alt="bg"
								/>
							</div>
						);

					case "video":
						return (
							<div className="h-[500px] flex justify-center items-center text-2xl font-bold text-white">
								video
							</div>
						);
					default:
						return null;
				}
			})}
		</div>
	);
};
export default RenderChildComponent;

import "quill/dist/quill.snow.css";
import { useState } from "react";
import ReactQuill from "react-quill";

const TextEditor = ({ callback, value }: any) => {
	const [data, setData] = useState("");
	const modules = {
		toolbar: [
			[{ size: ["small", false, "large", "huge"] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[{ list: "ordered" }, { list: "bullet" }],
			["link"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
				{ align: [] },
			],
			[
				{
					color: [
						"#000000",
						"#e60000",
						"#ff9900",
						"#ffff00",
						"#008a00",
						"#0066cc",
						"custom-color",
					],
				},
			],
		],
	};

	const formats = [
		"header",
		"height",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"color",
		"bullet",
		"indent",
		"link",
		"align",
		"size",
	];

	return (
		<div>
			<div className="py-2">
				<ReactQuill
					defaultValue={value}
					theme="snow"
					modules={modules}
					formats={formats}
					placeholder="write your content ...."
					onChange={(value) => {
						callback(value);
					}}
					style={{ height: "220px", borderRadius: "12px" }}
				></ReactQuill>
			</div>
		</div>
	);
};

export default TextEditor;

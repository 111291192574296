import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog';
import { DialogClose } from '@radix-ui/react-dialog';
import { MessageCircle } from 'lucide-react';
import { useState } from 'react';
import { CoverElemnt, Heading, ImageElement, Text, VideoElement } from '../../sampleData';

const ContorlDialog = ({ add }: any) => {
  const [data, setData] = useState([
    {
      name: 'Heading',
      element: Heading(),
      icon: ''
    },
    {
      name: 'text',
      element: Text(),
      icon: ''
    },
    {
      name: 'cover Element',
      element: CoverElemnt(),
      icon: ''
    },
    { name: 'Image Element', element: ImageElement(), icon: '' },
    { name: 'Video Element', element: VideoElement(), icon: '' }
  ]);

  return (
    <Dialog>
      <DialogTrigger className="flex gap-2 border-none outline-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="#fff"
          className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#000"
            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
        Add Control element
      </DialogTrigger>
      <DialogContent className="p-0">
        <DialogHeader>
          <DialogTitle className="text-center font-normal text-2xl border-b-2 py-3  border-indigo-500">
            Add New Control Element
          </DialogTitle>
          <div className="mx-auto max-w-md py-10 px-10 ">
            <div className="flex justify-start flex-wrap gap-3">
              {data.map((item: any) => {
                return (
                  <DialogClose key={item.name}>
                    <div
                      onClick={() => {
                        add(item.element);
                      }}
                      className="border cursor-pointer p-2 w-28 h-28 flex flex-col gap-2 justify-center items-center rounded-xl">
                      <MessageCircle />
                      <p className="text-lg font-normal text-center">{item.name}</p>
                    </div>
                  </DialogClose>
                );
              })}
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default ContorlDialog;

import { Input } from '@/components';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/hooks/useAuth';
import FileDropzone from '@/views/editor/components/settings/dropzone';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UseSetting from './hooks/useCustom';

import { Switch } from '@/components/ui/switch';
import { db } from '@/libs/firebase-config';
import CoverImageLayout from '@/views/editor/components/renderui/coverImageLayout';
import TextEditor from '@/views/editor/components/renderui/editor';
import RenderChildComponent from '@/views/editor/components/renderui/renderChildComponent';
import { collection, doc, setDoc } from 'firebase/firestore';
import moment from 'moment';
import Loading from './components/Loading';

const EditPage = () => {
  const [loading, setLoading] = useState(false);
  const [createFromData, setCreateFromData] = useState<any>();
  const [slug, setSlug] = useState('');
  const { getStudioStyle, getMenuDetail } = UseSetting();
  const { id, pageId } = useParams();

  const { user }: any = useAuth();
  const navigate = useNavigate();
  const [checkbox, setCheckBox] = useState(false);
  const [MenuData, setMenuData] = useState<any>();

  const getSetting = async () => {
    setLoading(true);
    try {
      const menu: any = await getMenuDetail(user?.uid, id, pageId);

      const data: any = await getStudioStyle(user?.uid, id);
      setCreateFromData({
        ...data,
        ...menu.formData,
      });

      setMenuData(menu ? menu : null);

      setSlug(menu?.slug);
      setCheckBox(menu?.status);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  function createSlug(inputString: string) {
    // Convert the string to lowercase
    let replaceSlug = inputString.toLowerCase();

    // Remove special characters and spaces
    replaceSlug = replaceSlug.replace(/[^\w\s-]/g, ''); // Remove non-word characters except spaces and hyphens
    replaceSlug = replaceSlug.replace(/\s+/g, '-'); // Replace spaces with hyphens

    return replaceSlug;
  }

  const onSubmit = () => {
    if (!slug) {
      return alert('please add slug input');
    }
    const newslug = createSlug(slug);

    const data = {
      id: MenuData?.id,
      slug: newslug,
      formData: createFromData,
      created_at: MenuData?.created_at,
      status: checkbox,
      updated_at: moment.utc(new Date()).format(),
    };
    addMenuDoc(data, id);
  };

  const addMenuDoc = async (documents: any, sid: any) => {
    const userId: any = user?.uid;
    const userRef = doc(db, 'users', userId, 'studio', sid);
    const MenuCollection = collection(userRef, 'menu');
    const documentId = documents.id;
    try {
      const documentRef = doc(MenuCollection, documentId);

      await setDoc(documentRef, documents);

      navigate(-1);
    } catch (error) {
      console.error(`Error adding document with ID: ${documentId}`, error);
    }
  };

  useEffect(() => {
    getSetting();
  }, []);

  const handleChange = (idx: number, vidx: number, v: string) => {
    const newValue = createFromData.page_content;
    const newPageContent = newValue.map((pageData: any, pageIdx: number) => {
      if (idx === pageIdx) {
        const newData = pageData.data.map((inputData: any, inputIdx: number) =>
          vidx === inputIdx ? { ...inputData, value: v } : { ...inputData }
        );
        return { ...pageData, data: newData };
      } else {
        return { ...pageData };
      }
    });
    setCreateFromData({ ...createFromData, page_content: newPageContent });
  };

  return (
    <div className="p-10">
      <div className="flex justify-between items-center">
        <h1>Edit {createFromData?.name} Page</h1>
        <div>
          <Button
            onClick={() => {
              navigate(-1);
            }}
            className="mr-4 bg-white text-black border hover:bg-black hover:text-white"
          >
            {' '}
            Back
          </Button>
          <Button onClick={onSubmit}> Update Page</Button>
        </div>
      </div>

      {!loading ? (
        <div className="grid grid-cols-2 gap-4">
          <div className="col-span-1">
            <div className="relative mt-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-600"
              >
                Please add your slug route
              </label>
              <p className=" absolute left-0 rounded-s-md z-10 bottom-0 py-3 bg-white px-4 border text-black ">
                {'/'}
              </p>
              <Input
                value={slug}
                name="slug"
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
                placeholder="eg.shop-1 ,shop-23-4"
                className="pl-14 mt-2 outline-none "
              />
            </div>

            <div className="relative  mt-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-600"
              >
                Status
              </label>
              <div className="flex gap-3 items-center">
                <div>
                  <Switch
                    className="mt-3"
                    checked={checkbox}
                    onClick={() => {
                      setCheckBox(!checkbox);
                    }}
                  />
                </div>
                <p>{checkbox ? 'Active' : 'UnActive'}</p>
              </div>
            </div>

            {createFromData?.page_content?.map((item: any, idx: number) => {
              if (!item.isHidden) {
                return null;
              }
              return (
                <div key={idx}>
                  <h3 className=" capitalize text-xl text-semibold my-3">
                    {item.components}
                  </h3>
                  <div className=" border  p-5 rounded-lg ">
                    {item?.data?.map((mappedData: any, vidx: number) => {
                      if (
                        mappedData.name === 'paragraph' ||
                        mappedData.name === 'cover_paragraph'
                      ) {
                        return (
                          <div key={vidx} className="py-5">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-600"
                            >
                              {mappedData.title}
                            </label>
                            <div className="mt-2">
                              <TextEditor
                                value={mappedData.value}
                                callback={(v: any) => {
                                  handleChange(idx, vidx, v);
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      switch (mappedData?.component) {
                        case 'input':
                          return (
                            <div className="py-5">
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-600"
                              >
                                {mappedData.title}
                              </label>
                              <div className="mt-2">
                                <Input
                                  value={mappedData.value}
                                  name={mappedData.name}
                                  onChange={(e) => {
                                    handleChange(idx, vidx, e.target.value);
                                  }}
                                  placeholder="eg. new-article"
                                  type="text"
                                  className="bg-white border"
                                />
                              </div>
                            </div>
                          );

                        case 'File':
                          return (
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium leading-6 text-gray-600"
                              >
                                Preivew Image
                              </label>
                              <FileDropzone
                                value={mappedData.value}
                                path={mappedData.name}
                                type={mappedData.name}
                                callBack={(v: string) => {
                                  handleChange(idx, vidx, v);
                                }}
                              />
                            </div>
                          );

                        case 'select':
                          <div className="py-2">
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium leading-6 text-gray-600"
                            >
                              Article Effect
                            </label>
                            <div className="mt-2">
                              <select className="border text-xs bg-white w-full px-2 py-3 rounded-md">
                                <option> Please Select Effect </option>
                                <option> color </option>
                              </select>
                            </div>
                          </div>;
                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="">
            <h1 className="py-4 font-bold text-2xl text-center">PREVIEW</h1>
            <div className="max-w-[350px] h-screen mx-auto py-5 overflow-auto bg-black w-full">
              {createFromData?.page_content?.map((item: any) => {
                if (item.isHidden) {
                  switch (item.components) {
                    case 'cover':
                      return <CoverImageLayout item={item} />;
                    default:
                      return <RenderChildComponent item={item} />;
                  }
                }
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default EditPage;

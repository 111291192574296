/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuth } from "@/hooks/useAuth";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import UseEditor from "../hooks/useEditor";
import { ThemeSettingContext } from "./themeContext";

export function useQuery() {
	const { search } = useLocation();

	return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ThemeSettingProvider = ({ children }: any) => {
	const params: any = useParams();
	const { getData, getStudio, SaveStudio } = UseEditor();
	const { user }: any = useAuth();
	const [uiData, setuidData] = useState([]);

	const [loading, setLoading] = useState(false);
	const [settingList, setSettingList] = useState<any>();

	const [updateSettingData, setUpdateSettingData] = useState<any>();
	console.log(uiData, "data");
	const fetchData = async () => {
		setLoading(true);
		try {
			const studio: any = await getStudio(user?.uid, params.id);
			const oldStudioPage_content = studio.page_content.map((item: any) => ({
				...item,
				oldContent: true,
			}));
			if (studio) {
				setuidData({ ...studio, page_content: oldStudioPage_content });
				filterSetting(
					{ ...studio, page_content: oldStudioPage_content },
					params?.id
				);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	const filterSetting = (studio: any, id: string) => {
		setSettingList(studio);

		setUpdateSettingData(studio.page_content[0]);
	};

	const addCompontent = (components: any) => {
		const newpageContent = [...settingList.page_content, components];
		setSettingList({ ...settingList, page_content: newpageContent });
	};

	//c

	const updateFixData = (id: any, updateValue: any) => {
		const newJson = settingList.page_content?.map((item: any) =>
			item._uuid === id ? { ...updateValue } : { ...item }
		);
		setSettingList({ ...settingList, page_content: newJson });
	};

	const changeSetting = (uuid: string) => {
		const newSetting = settingList?.page_content.find((item: any) => {
			return item._uuid === uuid;
		});

		setUpdateSettingData(newSetting);
	};

	useEffect(() => {
		// eslint-disable-next-line react-hooks/exhaustive-deps
		fetchData();
	}, []);

	const handleSave = () => {
		console.log(uiData, "uid");
		SaveStudio(settingList, params.id);
	};
	const removeItem = (uuid: string) => {
		const newSetting = settingList?.page_content.filter((item: any) => {
			return item._uuid !== uuid;
		});
		console.log(newSetting, uuid);

		setSettingList({ ...settingList, page_content: newSetting });
	};

	return (
		<ThemeSettingContext.Provider
			value={{
				loading,
				setSettingList,
				uiData,
				settingList,
				filterSetting,
				addCompontent,
				updateFixData,
				updateSettingData,
				setUpdateSettingData,
				changeSetting,
				handleSave,
				removeItem,
			}}
		>
			{children}
		</ThemeSettingContext.Provider>
	);
};

export { ThemeSettingProvider };

import { FBstorage } from "@/libs/firebase-config";
import { getDownloadURL, ref } from "firebase/storage";
import { useEffect, useState } from "react";

const FirebaseImage = ({ path, className, alt }: any) => {
	const [url, setUrl] = useState("");

	const getImageUrl = async (p: string) => {
		const imageRef = ref(FBstorage, p);

		try {
			const res = await getDownloadURL(imageRef);
			setUrl(res);
		} catch (error) {
			console.error("Error loading image:", error);
			// You can return a fallback URL or an empty string as needed
			return "fallback_url";
		}
	};

	useEffect(() => {
		if (path?.includes("/local")) {
			setUrl(path);
		} else {
			getImageUrl(path);
		}
	}, [path]);

	return url ? <img src={url} className={className} alt={alt} /> : null;
};

export default FirebaseImage;

import { useContext } from 'react';
import { Input } from '../../../../components';
import { ThemeSettingContext } from '../../context/themeContext';
import FileDropzone from './dropzone';

const Setting = () => {
  const { updateSettingData, setUpdateSettingData, updateFixData } =
    useContext(ThemeSettingContext);

  const handleChange = (idx: number, value: any) => {
    const newUpdateSetting = updateSettingData?.data.map((item: any, i: number) => {
      if (idx === i) {
        return { ...item, value: value };
      } else {
        return { ...item };
      }
    });
    setUpdateSettingData({ ...updateSettingData, data: newUpdateSetting });
    updateFixData(updateSettingData._uuid, { ...updateSettingData, data: newUpdateSetting });
  };

  return (
    <div className="fixed bg-white z-0 top-0 right-0 shadow-md h-full pt-20 w-[300px] ">
      <div className="px-5 py-2 flex items-center gap-5 text-black">
        <h2 className="font-bold text-xl capitalize">{updateSettingData?.components} Settings</h2>
      </div>
      <div className="px-5 space-y-1 mt-2 py-2">
        {updateSettingData?.data.map((item: any, idx: number) => {
          switch (item?.component) {
            case 'input':
              return (
                <div className="py-5">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-600">
                    {item.title}
                  </label>
                  <div className="mt-2">
                    <Input
                      value={item.value}
                      name={item.name}
                      onChange={(e) => {
                        handleChange(idx, e.target.value);
                      }}
                      placeholder="eg. new-article"
                      type="text"
                      className="bg-white border"
                    />
                  </div>
                </div>
              );
            case 'File':
              return (
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-600">
                    Preivew Image
                  </label>
                  <FileDropzone
                    value={item.value}
                    path={item.name}
                    type={item.name}
                    callBack={(v: string) => {
                      handleChange(idx, v);
                    }}
                  />
                </div>
              );

            case 'select':
              <div className="py-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-600">
                  Article Effect
                </label>
                <div className="mt-2">
                  <select className="border text-xs bg-white w-full px-2 py-3 rounded-md">
                    <option> Please Select Effect </option>
                    <option> color </option>
                  </select>
                </div>
              </div>;
            default:
              return null;
          }
        })}
      </div>
    </div>
  );
};

export default Setting;

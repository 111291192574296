import { Route, Routes } from 'react-router-dom';
import './App.css';
import { HomeLayout } from './routes/homeLayout';
import { ProtectedLayout } from './routes/protectedLayout';
import CreatePage from './views/dashboard/custom-pages/create';
import DetailPage from './views/dashboard/custom-pages/detail';
import EditPage from './views/dashboard/custom-pages/edit';
import ThemeDataEditor from './views/dashboard/custom-pages/editor/index';
import CustomPage from './views/dashboard/custom-pages/index';
import EcommercePage from './views/dashboard/e-commerce';
import SettingPage from './views/dashboard/settings';
import Editor from './views/editor';
import LoginPage from './views/login';

function App() {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<LoginPage />} />
      </Route>

      <Route path="/dashboard" element={<ProtectedLayout />}>
        <Route path="" element={<EcommercePage />} />
        <Route path="settings" element={<SettingPage />} />
        <Route path="theme" element={<CustomPage />} />
        <Route path="theme/:id" element={<CustomPage />} />
        <Route path="theme/:id/create" element={<CreatePage />} />
        <Route path="theme/:id/detail/:pageId" element={<DetailPage />} />
        <Route path="theme/:id/edit/:pageId" element={<EditPage />} />

        <Route path="theme-editor/:id/create" element={<ThemeDataEditor />} />
        <Route
          path="theme-editor/:id/edit/:pageId"
          element={<ThemeDataEditor />}
        />
        <Route path="editor/:id" element={<Editor />} />
      </Route>
    </Routes>
  );
}

export default App;

import FirebaseImage from "@/components/firebaseImage";

const CoverImageLayout = ({ item }: any) => {
	return (
		<div className="relative mx-5 h-[500px]">
			<FirebaseImage
				className="h-full top-0 left-0 z-0 object-cover  w-full"
				path={item.data[0].value}
				alt="bg"
			/>
			<div className="absolute top-0 left-0  h-[500px] flex  flex-col justify-center items-start mx-5">
				{item?.data.map((comp: any) => {
					switch (comp.name) {
						case "cover_head":
							return (
								<h3 className=" text-white z-1 text-3xl font-bold">
									{comp.value}
								</h3>
							);
						case "cover_paragraph":
							return (
								<div
									className=" text-white py-2"
									dangerouslySetInnerHTML={{ __html: comp.value }}
								></div>
							);
						default:
							return null;
					}
				})}
			</div>
		</div>
	);
};
export default CoverImageLayout;

import ColorPicker from "@/components/colorPicker";
import { Switch } from "@/components/ui/switch";
import { useAuth } from "@/hooks/useAuth";
import { useLayout } from "@/hooks/useLayout";
import { auth, FBstorage } from "@/libs/firebase-config";
import UseEditor from "@/views/editor/hooks/useEditor";
import { ref, uploadBytes } from "firebase/storage";
import { Pencil, Plus, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Input } from "src/components";
import { MissionControl } from "src/utils/menuList";
import { v4 } from "uuid";
import FirebaseImage from "../../../components/firebaseImage";
import DialogPage from "./components/dialog";
import UpdateDialogPage from "./components/updateDialog";
import UseSetting from "./hooks/useSetting";

const SettingPage = () => {
	const navigate = useNavigate();
	const { updateUser, first, getUserSetting, deleteDocData } = UseSetting();
	const { user }: any = useAuth();
	const [loading, setLoading] = useState(false);
	const { handleReload } = useLayout();
	const [perviousData, setPerviousData] = useState<any>();
	const { getData } = UseEditor();
	const {
		register,
		watch,
		reset,
		setValue,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<any>({
		defaultValues: {
			primaryColor: "#E0E0E0",
			secondaryColor: "#E0E0E0",
			brandName: "",
			template: "",
			content: [
				{ id: v4(), name: "Home", route: "", icons: "/local/icons/home.svg" },
			],
			Menu_content: [
				{ id: v4(), name: "shop", route: "", icons: "/local/icons/home.svg" },
			],
			logo: null,
			pwa_logo: null,
			shopify_active: false,
			shopify_key: "",
		},
	});

	const setSetting = async () => {
		setLoading(true);
		try {
			const data: any = await getUserSetting(user?.uid);

			if (data) {
				reset(data?.settings);
				setPerviousData(data?.settings);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		setSetting();
	}, []);

	const uploadImage = (v: null | File, path: string) => {
		if (v == null) return;
		const imageRef = ref(
			FBstorage,
			`/company-${auth.currentUser?.uid}/${path}/${v4()}`
		);
		const res = uploadBytes(imageRef, v).then((snapShot) => {
			return snapShot.metadata.fullPath;
		});
		return res;
	};

	const onSubmit = async (data: FieldValues) => {
		let logo;
		if (data.logo instanceof File) {
			logo = await uploadImage(data.logo, "logo");
		} else {
			logo = data.logo;
		}
		let pwa_logo;
		if (data.pwa_logo instanceof File) {
			pwa_logo = await uploadImage(data.pwa_logo, "pwa_logo");
		} else {
			pwa_logo = data.pwa_logo;
		}

		const filterNewContent = data.content.map((c: any) => {
			if (c.isNew) {
				delete c.isNew;
				return { ...c };
			} else {
				return { ...c };
			}
		});

		const uploadData = {
			primaryColor: data.primaryColor,
			secondaryColor: data.secondaryColor,
			brandName: data.brandName,
			template: data.template,
			Menu_content: data.Menu_content,
			content: filterNewContent,
			logo: logo,
			pwa_logo: pwa_logo,
			shopify_active: data.shopify_active,
			shopify_key: data.shopify_key,
		};

		const idsToDelete = perviousData?.content
			.map((item1: any) => item1.id)
			.filter(
				(id: any) => !uploadData?.content.some((item2: any) => item2.id === id)
			);
		for (let index = 0; index < idsToDelete?.length; index++) {
			await deleteDocData(user?.uid, idsToDelete[index]);
		}

		try {
			await updateUser(uploadData);
			setTimeout(async () => {
				await getData(user?.uid);
			}, 1000);
			setSetting();
			handleReload();
		} catch (error) {}
	};

	if (loading) {
		return null;
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="p-10">
				<div className="flex flex-row items-center p-1 gap-x-2">
					<MissionControl width={25} height={25} />
					<p className=" text-2xl">Mission Control</p>
				</div>
				<div className="border shadow-md rounded-md mt-4 md:mt-2">
					<div className=" flex px-3 py-2  items-center justify-between border-b">
						<div className="flex flex-row  items-center p-1 gap-x-2">
							<Pencil />
							<p className=" font-bold text-xl">Appearance</p>
						</div>
						<div className="flex gap-3 items-center ">
							<p className="text-gray-300"> preview</p>
							<button
								type="submit"
								className="inline-flex items-center gap-x-1.5 rounded-md bg-black px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-600	focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
							>
								Update
							</button>
						</div>
					</div>
					<div className="px-10 py-5 gap-y-5 gap-x-5 grid items-center grid-cols-12">
						<div className="col-span-3  md:col-span-2 ">Brand Name</div>
						<div className="col-span-9 md:col-span-10  grid grid-cols-12">
							<div className="col-span-12 md:col-span-6">
								<Input
									register={register}
									type="text"
									name="brandName"
									className=""
								/>
							</div>
						</div>

						<div className="col-span-3 md:col-span-2">Template</div>
						<div className="col-span-9 md:col-span-10  grid grid-cols-12">
							<div className="col-span-12 md:col-span-6">
								<Input
									register={register}
									type="text"
									name="template"
									className=""
								/>
							</div>
						</div>

						<div className="col-span-3 md:col-span-2">Content Page</div>
						<div className="col-span-9 md:col-span-10 ">
							<div className="flex flex-wrap gap-5 items-center">
								{watch("content").length !== 0 &&
									watch("content").map((item: any, idx: number) => {
										return (
											<div
												key={idx}
												className="bg-indigo-800 items-center  font-semibold  text-white px-3 py-2 flex gap-3 rounded-full"
											>
												<div
													onClick={() => {
														if (item.isNew || first) {
															return alert("can route util update");
														} else {
															navigate(`/dashboard/editor/${item.id}`);
														}
													}}
													className="flex items-center gap-3"
												>
													{item.icons && (
														<div className="w-4 h-4">
															<FirebaseImage path={item.icons} />
														</div>
													)}
													<p>{item.name}</p>
												</div>
												<UpdateDialogPage
													idx={idx}
													contentData={item}
													text={"Update Page"}
													uploadImage={uploadImage}
													callback={(v: any) => {
														const newContent = watch("content");
														newContent[idx] = { isNew: true, ...v };

														setValue("content", newContent, {
															shouldDirty: true,
															shouldValidate: true,
														});
													}}
												/>
												{idx !== 0 && (
													<div
														onClick={() => {
															const newContent = watch("content");
															newContent.splice(idx, 1);
															setValue("content", newContent, {
																shouldDirty: true,
															});
														}}
														className="hover:bg-gray-300/30 w-5 h-5  flex items-center justify-center rounded-sm"
													>
														<Trash2 size={16} />
													</div>
												)}
											</div>
										);
									})}
							</div>
						</div>

						<div className="col-span-3 md:col-span-2"></div>
						<div className="col-span-9 md:col-span-10 ">
							<DialogPage
								text={"Add Page"}
								uploadImage={uploadImage}
								callback={(v: any) => {
									setValue(
										"content",
										[...watch("content"), { isNew: true, ...v }],
										{
											shouldDirty: true,
										}
									);
								}}
							/>
						</div>

						<div className="col-span-3 md:col-span-2 h-full py-2 flex items-start">
							Logo Upload
						</div>
						<div className="col-span-9 md:col-span-10  py-2">
							<label htmlFor="logo" className="relative cursor-pointer">
								<div className="rounded-full border flex justify-center items-center w-10 h-10">
									<Plus className="w-4 h-4" />
								</div>
								<input
									type="file"
									id="logo"
									accept="image/*"
									className="hidden"
									onChange={(e: any) => setValue("logo", e.target.files[0])}
								/>
								<div>
									{watch("logo") && (
										<div className="rounded-full absolute top-0 bg-white left-0 z-10 border flex justify-center items-center w-10 h-10">
											{watch("logo") instanceof File ? (
												<img
													width="63"
													height="50"
													className="w-full h-full rounded object-contain scale-10"
													src={URL.createObjectURL(watch("logo") as any)}
													alt="license"
												/>
											) : (
												<FirebaseImage path={watch("logo")} />
											)}
										</div>
									)}
								</div>
							</label>
							<p className="text-xs mt-2">(450 x 84 px required)</p>
						</div>
						<div className="col-span-3 md:col-span-2 py-2 h-full flex items-start">
							Pwa Upload
						</div>
						<div className="col-span-9 md:col-span-10   py-2">
							<label htmlFor="pwa_logo" className="relative cursor-pointer">
								<div className="rounded-full border flex justify-center items-center w-10 h-10">
									<Plus className="w-4 h-4" />
								</div>
								<input
									type="file"
									id="pwa_logo"
									accept="image/*"
									className="hidden"
									onChange={(e: any) => setValue("pwa_logo", e.target.files[0])}
								/>
								<div>
									{watch("pwa_logo") && (
										<div className="rounded-full absolute top-0 bg-white left-0 z-10 border flex justify-center items-center w-10 h-10">
											{watch("pwa_logo") instanceof File ? (
												<img
													width="63"
													height="50"
													className="w-full h-full rounded object-contain scale-10"
													src={URL.createObjectURL(watch("pwa_logo") as any)}
													alt="license"
												/>
											) : (
												<FirebaseImage path={watch("pwa_logo")} />
											)}
										</div>
									)}
								</div>
							</label>
							<p className="text-xs mt-2">(450 x 84 px required)</p>
						</div>
						<div className="col-span-3  md:col-span-2 h-full flex items-start">
							Primary color
						</div>
						<div className="col-span-9 md:col-span-10  ">
							<ColorPicker
								currentColor={watch("primaryColor")}
								setCurrentColor={(color) => {
									setValue("primaryColor", color);
								}}
							/>
						</div>
						<div className="col-span-3  md:col-span-2 h-full flex items-start">
							Secondary Color
						</div>
						<div className="col-span-9 md:col-span-10  ">
							<ColorPicker
								currentColor={watch("secondaryColor")}
								setCurrentColor={(scolor) => {
									setValue("secondaryColor", scolor);
								}}
							/>
						</div>
						<div className="col-span-3 md:col-span-2">Menu Content</div>
						<div className="col-span-9 md:col-span-10 ">
							<div className="flex flex-wrap gap-5 items-center">
								{watch("Menu_content").length !== 0 &&
									watch("Menu_content").map((item: any, idx: number) => {
										return (
											<div
												key={idx}
												className="bg-indigo-800 font-semibold items-center text-white px-3 py-2 flex gap-3 rounded-full"
											>
												{item.icons && (
													<div className="w-4 h-4">
														<FirebaseImage path={item.icons} />
													</div>
												)}
												<p className="min-w-[50px] text-center">{item.name}</p>

												<UpdateDialogPage
													idx={idx}
													contentData={item}
													text={"Update Menu Content Page"}
													uploadImage={uploadImage}
													callback={(v: any) => {
														const newContent = watch("Menu_content");
														newContent[idx] = v;

														setValue("Menu_content", newContent);
													}}
												/>
												{idx !== 0 && (
													<div
														onClick={() => {
															if (idx !== 0) {
																const newContent = watch("Menu_content");
																newContent.splice(idx, 1);
																setValue("Menu_content", newContent);
															}
														}}
														className="hover:bg-gray-300/30 w-5 h-5  flex items-center justify-center rounded-sm"
													>
														<Trash2 size={16} />
													</div>
												)}
											</div>
										);
									})}
							</div>
						</div>
						<div className="col-span-3 md:col-span-2"></div>
						<div className="col-span-9 md:col-span-10  flex gap-5">
							<DialogPage
								text={"Add Link"}
								uploadImage={uploadImage}
								callback={(v: any) => {
									setValue("Menu_content", [...watch("Menu_content"), v]);
								}}
							/>
						</div>
						<div className="col-span-3 md:col-span-2">Shopfiy Active</div>
						<div className="col-span-9 md:col-span-10  grid grid-cols-12">
							<Switch
								checked={watch("shopify_active")}
								onCheckedChange={() =>
									setValue("shopify_active", !watch("shopify_active"))
								}
							/>
						</div>
						<div className="col-span-3 md:col-span-2">Shopfiy Key</div>
						<div className="col-span-9 md:col-span-10  grid grid-cols-12">
							<div className="col-span-12 md:col-span-4">
								<Input
									register={register}
									name="shopify_key"
									className="!rounded-full"
									type="password"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

export default SettingPage;

import { v4 } from "uuid";

export const Heading = () => {
	return {
		_uuid: v4(),
		components: "Heading",
		isHidden: true,
		data: [
			{
				value: "Hello world",
				component: "input",
				title: "Heading text",
				name: "head",
				placeholder: "",
				componentKey: v4(),
			},
		],
	};
};

export const Text = () => {
	return {
		_uuid: v4(),
		components: "text",
		isHidden: true,
		data: [
			{
				value:
					"From Vinyasa and Hatha to Yin and Restorative, explore 3,000 yoga videos across twelve different styles. Learn from the world's best yoga teachers, at home or on the go.",
				component: "input",
				name: "paragraph",
				placeholder: "",
				title: "Paragraph",
				componentKey: v4(),
			},
		],
	};
};
export const CoverElemnt = () => {
	return {
		_uuid: v4(),
		components: "cover",
		isHidden: true,
		data: [
			{
				value: "/local/icons/placeholder.png",
				component: "File",
				title: "Cover Image",
				placeholder: "",
				name: "cover_image",
				componentKey: v4(),
			},
			{
				value: "Hello world",
				component: "input",
				title: "Heading text",
				name: "cover_head",
				placeholder: "",
				componentKey: v4(),
			},

			{
				value:
					"From Vinyasa and Hatha to Yin and Restorative, explore 3,000 yoga videos across twelve different styles. Learn from the world's best yoga teachers, at home or on the go.",
				component: "input",
				name: "cover_paragraph",
				placeholder: "",
				title: "Paragraph",
				componentKey: v4(),
			},
		],
	};
};

export const ImageElement = () => {
	return {
		_uuid: v4(),
		components: "image",
		isHidden: true,
		data: [
			{
				value: "/local/icons/placeholder.png",
				component: "File",
				title: "Image",
				placeholder: "",
				name: "image",
				componentKey: v4(),
			},
		],
	};
};

export const VideoElement = () => {
	return {
		_uuid: v4(),
		components: "video",
		isHidden: true,
		data: [
			{
				value: "/local/icons/placeholder.png",
				component: "File",
				title: "Video Image",
				placeholder: "",
				name: "video",
				componentKey: v4(),
			},
		],
	};
};

import { useAuth } from "@/hooks/useAuth";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CoverImageLayout from "@/views/editor/components/renderui/coverImageLayout";
import RenderChildComponent from "@/views/editor/components/renderui/renderChildComponent";
import UseSetting from "./hooks/useCustom";

const DetailPage = () => {
	const [loading, setLoading] = useState(false);
	const { id, pageId } = useParams();
	const { getMenuDetail } = UseSetting();
	const [MenuData, setMenuData] = useState<any>();
	const { user }: any = useAuth();
	const navigate = useNavigate();

	const getSetting = async () => {
		setLoading(true);
		try {
			const data: any = await getMenuDetail(user?.uid, id, pageId);

			setMenuData(data ? data : null);
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	useEffect(() => {
		getSetting();
	}, []);

	return (
		<>
			<div className="max-w-[350px] h-screen mx-auto py-5 overflow-auto bg-black w-full h-full">
				{MenuData?.formData?.page_content?.map((item: any) => {
					if (item.isHidden) {
						switch (item.components) {
							case "cover":
								return <CoverImageLayout item={item} />;
							default:
								return <RenderChildComponent item={item} />;
						}
					}
				})}
			</div>
		</>
	);
};

export default DetailPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { auth, db } from "@/libs/firebase-config";
import {
	collection,
	doc,
	getDoc,
	getDocs,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import { Heading } from "../sampleData";

const UseEditor = () => {
	const updateStudio = async (data: any) => {
		const userId: any = auth.currentUser?.uid;
		const userRef = doc(db, "users", userId);
		try {
			await updateDoc(userRef, {
				studio: data,
			});
			// alert('success');
		} catch (error) {}
	};

	const getStudio = async (uid: string, sId: string) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId, "studio", sId);

		try {
			const user = await getDoc(userRef);

			if (user.exists()) {
				const data: any = user.data();
				return data;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const addStudioDoc = async (documents: any) => {
		const userId: any = auth.currentUser?.uid;
		const userRef = doc(db, "users", userId);
		const studioCollection = collection(userRef, "studio");

		try {
			documents.forEach(async (documentData: any) => {
				const documentId = documentData.id;
				const documentRef = doc(studioCollection, documentId);
				try {
					await setDoc(documentRef, documentData);
				} catch (error) {
					console.error(`Error adding document with ID: ${documentId}`, error);
				}
			});

			// alert('success');
		} catch (error) {}
	};

	const SaveStudio = async (data: any, sid: any) => {
		const userId: any = auth.currentUser?.uid;
		const userRef = doc(db, "users", userId, "studio", sid);
		console.log(data);
		try {
			await updateDoc(userRef, data);
			alert("success");
		} catch (error) {}
	};

	const getData = async (uid: any) => {
		const userId: any = uid;

		const userRef = doc(db, "users", userId);
		const user = await getDoc(userRef);

		if (user.exists()) {
			const studioCollectionRef = collection(userRef, "studio");
			const studio = await getDocs(studioCollectionRef);
			const data: any = user.data();
			const studioList: any[] = [];
			studio.forEach((v: any) => {
				const studioData = v.data();
				studioList.push(studioData);
			});

			// check data
			if (!studio.empty) {
				if (data.settings.content.length === studioList.length) {
					return data.studio;
				} else {
					const idSet1 = new Set(studioList.map((item: any) => item.id));

					// Filter array2 to include only items with IDs not in idSet1
					const newPage = data.settings.content.filter(
						(item: any) => !idSet1.has(item.id)
					);
					const newStructure = newPage.map((item: any) => {
						return { ...item, page_content: [Heading()] };
					});
					addStudioDoc(newStructure);
				}
			}

			if (studio.empty) {
				const newStructure = data.settings.content.map((item: any) => {
					return { ...item, page_content: [Heading()] };
				});

				addStudioDoc(newStructure);
				return newStructure;
			}
		} else {
			return null;
		}
	};

	return { updateStudio, getStudio, getData, SaveStudio };
};

export default UseEditor;

import classNames from "classnames";
import { Eye, EyeOff, GripHorizontal, Trash2 } from "lucide-react";
import { useContext, useState } from "react";
import {
	SortableContainer,
	SortableElement,
	SortableHandle,
} from "react-sortable-hoc";
import { ThemeSettingContext } from "../../context/themeContext";
import UseSortable from "../../hooks/useSortable";
import ControlDialog from "../controlDialog";

const DragHandle = SortableHandle(() => (
	<span className="cursor-pointer">
		<GripHorizontal size={20} />
	</span>
));

const SortableItem = SortableElement<any>(
	({ item, changeHidden, removeItem, changeSetting }: any) => {
		const [show, setShow] = useState(false);
		console.log(item, "item");
		return (
			<div
				onMouseEnter={() => {
					setShow(true);
				}}
				onMouseLeave={() => {
					setShow(false);
				}}
				style={{ position: "relative" }}
				className="bg-white"
			>
				<div className="">
					<div className="flex  px-2 py-2 gap-5 overflow-hidden bg-white  justify-between cursor-pointer">
						<div
							onClick={() => {
								changeSetting(item._uuid);
							}}
							className="flex items-center gap-2"
						>
							<div className="">&#9654;</div>
							<div className="text-label-lg font-semibold uppercase">
								{item.components}
							</div>
						</div>

						<div
							className={classNames(
								show ? "opacity-100" : "opacity-0",
								"flex gap-3 items-center "
							)}
						>
							<div
								onClick={() => {
									changeHidden(item._uuid, "isHidden", !item.isHidden);
								}}
							>
								{item.isHidden ? <Eye size={20} /> : <EyeOff size={20} />}
							</div>
							{!item.oldContent && (
								<div
									onClick={() => {
										removeItem(item._uuid);
									}}
								>
									<Trash2 className="w-4" />
								</div>
							)}
							<DragHandle />
						</div>
					</div>
				</div>
			</div>
		);
	}
);
const SortableWapper = SortableContainer<any>(({ children }: any) => {
	return <div style={{ position: "relative" }}>{children}</div>;
});

const Sidebar = () => {
	const { settingList, addCompontent, changeSetting, removeItem } =
		useContext(ThemeSettingContext);

	const { onSortEnd, handleChangeHidden } = UseSortable();

	return (
		<>
			<div className="fixed  bg-white z-0 top-0 left-0 shadow-md h-full pt-20 w-[300px] ">
				<div>
					<div className="px-5 py-2 flex items-center gap-5 text-gray-400 border-b">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-4 h-4"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
							/>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
							/>
						</svg>
						{settingList?.name} Setting
					</div>
					<div className="px-5  overflow-hidden space-y-4 mt-2 py-2">
						<div className="flex flex-col bg-white">
							<div className="">
								<SortableWapper onSortEnd={onSortEnd} useDragHandle>
									{settingList?.page_content.map((item: any, idx: any) => (
										<SortableItem
											changeSetting={changeSetting}
											changeHidden={handleChangeHidden}
											removeItem={removeItem}
											item={item}
											key={idx}
											index={idx}
										/>
									))}
								</SortableWapper>
							</div>
						</div>
					</div>
					<div className="px-5 absolute bottom-0 w-full py-4 flex items-center gap-2 text-black border-t">
						<ControlDialog add={addCompontent} />
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
